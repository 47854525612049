<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <!-- <aside class="page__sidebar">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Materiais coletados</h1>
              <h5 class="sidebar__subtitle">
                Histórico de materiais coletados.
              </h5>
            </div>
          </aside> -->

          <div class="page__content">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Coletas</h1>
              <div class="buttons">
                <button type="button" @click="print()" class="button_bg">
                  <i class="fas fa-print"></i>
                  Imprimir Coletas
                </button>
                <!-- <button
                  type="button"
                  class="button_outline"
                  @click="openModal(item)"
                >
                  Cadastrar Coleta
                </button> -->
              </div>
            </div>
            <div class="tabs__flex">
              <!-- <div class="tabs__nav">
                <a class="tabs__anchor current" href="#all" title="Todos"
                  >Todos</a
                >
                <a class="tabs__anchor" href="#app" title="Aplicativo"
                  >Aplicativo</a
                >
                <a class="tabs__anchor" href="#manual" title="Manual">Manual</a>
              </div> -->
              <div></div>
              <div class="tabs__search">
                <form class="search__form">
                  <input
                    class="form__input"
                    type="text"
                    placeholder="Pesquisar..."
                    @input="debounceInput"
                  />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </form>
              </div>
            </div>

            <div class="tab__body current" id="collectionsTable">
              <table class="table">
                <thead>
                  <tr>
                    <th>Gerador</th>
                    <th>Coletor</th>
                    <th>Material</th>
                    <th>Quantidade</th>
                    <th>Valor Pago(Un.)</th>
                    <th>Valor Total</th>
                    <th>Cidade</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="collection in collections" :key="collection.id">
                    <td>
                      <span> {{ collection.generator }} </span>
                    </td>
                    <td>
                      <span>{{ collection.coletor }}</span>
                    </td>
                    <td>
                      <span>{{ collection.material }}</span>
                    </td>
                    <td>
                      <span
                        >{{ collection.ammount }} {{ collection.unity }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{
                          collection.points
                            ? collection.points / collection.ammount
                            : collection.points | price
                        }}
                      </span>
                    </td>
                    <td>
                      <span>{{ collection.points | price }}</span>
                    </td>
                    <td>
                      <span>
                        {{ collection.city }} - {{ collection.province }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginator
              v-model="pagination.page"
              :pages="pagination.pages"
              v-show="collections.length"
            />
          </div>
        </div>
      </div>
    </section>
    <modal-register
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
    ></modal-register>
  </main>
</template>

<script>
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import _ from 'lodash'

Vue.use(VueHtmlToPaper, {
  styles: ['css/print.css']
})

export default {
  data: () => ({
    modal: false,
    search: '',
    collections: [],
    pagination: {
      page: 1,
      pages: 1
    }
  }),
  components: {
    modalRegister: () => import('./components/modal-register-collection'),
    paginator: () => import('@/components/paginator.vue')
  },
  methods: {
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value
    }, 300),
    async getCollections () {
      const url = `collections?page=${this.pagination.page}&search=${this.search}`
      const { data } = await this.$http.get(url)
      this.pagination.page = data.current_page
      this.pagination.pages = data.last_page
      this.collections = data.data
    },
    openModal (item) {
      this.selectedEntity = item
      this.modal = true
    },
    print () {
      this.$htmlToPaper('collectionsTable')
    }
  },
  mounted () {
    this.getCollections()
  },
  filters: {
    price (val) {
      return `R$ ${parseFloat(val).toFixed(2)}`.replace('.', ',')
    }
  },
  watch: {
    'pagination.page' () {
      this.getCollections()
    },
    search (val) {
      if (val.length < 3 && val.length > 0) {
        return false
      }
      this.getCollections()
    }
  }
}
</script>

<style>
.sidebar__header {
  display: flex;
  justify-content: space-between;
}
.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

table thead th:nth-child(3) {
  text-align: left;
}
</style>
